<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>绑定银行卡</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <a-spin tip="支付中..." :spinning="loading">
      <!-- 支付内容   -->
      <div class="card__container">
      <!-- 银行logo -->
      <div class="bankLogo"></div>
      <!--   支付金额   -->
      <div class="count">
        <div>在线支付</div>
        <div class="money">
          实付￥
          <span>{{ realTransAmt }}</span>
        </div>
        <div class="paid">充值￥{{ transAmt }}</div>
        <div class="orderId">交易单号{{ orderId }}</div>
      </div>
      <div class="numberInfo">
        <!-- 预留手机号 -->
        <div class="mobile">
          <span class="text">银行预留手机号：</span>
          {{ mobile }}
        </div>
        <!-- 验证码     -->
        <div class="code">
          <span class="text">请输入短信验证码：</span>
          <div class="smscode">
            <SmsCode @complete="handleComplete" />
          </div>
        </div>
      </div>
    </div>
    </a-spin>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";
import SmsCode from "@/components/smsCode.vue";
import { confirmQuickPay } from "@/services/FinancialService";
import { checkRechargeDetail } from "@/services/PayService";
export default {
  name: "bankPaid",
  components: { BreadcrumbItem, Breadcrumb, SmsCode },
  data() {
    return {
      realTransAmt: this.$route.query.realTransAmt || "", // 实付金额
      transAmt: this.$route.query.transAmt || "", // 充值金额
      mobile: this.$route.query.mobile || "",
      orderId: this.$route.query.orderId || "",
      timer: null, // 计时器
      loading: false // 支付中loading
    };
  },
  methods: {
    // 验证码输入完毕后触发
    handleComplete(val) {
      this.loading = true;
      // 拿到了验证码的值 确认绑定
      const smscode = val.join("");
      // 快捷绑卡确认
      const obj = {
        orderId: this.orderId,
        smsCode: smscode,
        goodsDesc: "预付类账号充值"
      };
      confirmQuickPay(obj)
        .then(() => {
          // 轮询 支付完成后到下个页面
          this.timer = setInterval(async () => {
            const { data } = await checkRechargeDetail(this.orderId);
            if (data.data.orderStatus === "3" || data.data.orderStatus === "9") {
              this.loading = false;
              // 跳转到支付结果页面
              this.$router.push({
                name: "RechargeResult",
                params: { orderId: this.orderId }
              });
            }
          }, 500);
        })
        .catch(() => {
          // 充值失败 返回上一个页面
          this.$router.back();
        });
    }
  },
  beforeDestroy() {
    // 清楚计时器
    clearInterval(this.timer);
  }
};
</script>

<style scoped>
.card__container {
  min-height: 600px;
  box-sizing: border-box;
  padding: 0 50px;
}
.bankLogo {
  height: 140px;
}
.count {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
}
.numberInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 47px;
}
.money {
  color: rgba(255, 89, 106, 1);
  font-size: 20px;
  font-weight: bold;
  height: 58px;
  line-height: 58px;
}
.money span {
  font-size: 50px;
}
.paid {
  margin-top: 6px;
  margin-bottom: 6px;
}
.mobile,
.code {
  width: 600px;
  color: rgba(16, 16, 16, 1);
  font-size: 22px;
}
.text {
  display: inline-block;
  color: rgba(16, 16, 16, 1);
  font-size: 22px;
  width: 198px;
  margin-right: 24px;
}
.code {
  display: flex;
  align-items: center;
    margin-top: 20px;
}
.smscode {
  width: 300px;
}
.orderId {
  color: rgba(102, 102, 102, 1);
}
</style>
