<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>充值</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="main__container">
      <div class="select_way">请选择支付方式</div>
      <div class="recharge__num">充值¥{{ price }}</div>
      <div class="recharge__no">交易单号：{{ orderId || "- -" }}</div>
      <a-tabs type="card" v-model="payWay">
        <a-tab-pane key="wechat">
          <div slot="tab" class="pay__tab">
            <img src="../../assets/order/icon-wechat.png" alt="icon_pay" />
            微信
          </div>
          <div class="qr__code__container">
            <img :src="wechatUrl" class="wechat__img" />
            <div class="qr__code__name">
              微信app扫码支付
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="alipay">
          <div slot="tab" class="pay__tab">
            <img src="../../assets/order/icon-alipay.png" alt="icon_pay" />
            支付宝
          </div>
          <div class="qr__code__container">
            <div ref="alipayQrCode"></div>
            <div
              class="qr__code__name"
              v-if="transStat === '' || transStat === 'P' || transStat === 'I'"
            >
              支付宝app扫码支付
            </div>
            <div class="qr__code__name" v-if="transStat === 'S'">
              支付宝支付成功
            </div>
            <div class="qr__code__name" v-if="transStat === 'F'">
              支付宝支付失败
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="bank">
          <div slot="tab" class="pay__tab">
            <img src="../../assets/order/icon-bank.png" alt="icon_pay" />
            网银
          </div>
          <div class="bank__card__container">
            <!-- 已绑定的银行卡列表 -->
            <div
              v-for="(item, index) in bankList"
              :key="index"
              :class="['btn--add', { actived: index === currentIndex }]"
              @click="chooseBank(item, index)"
            >
              <div class="pay__tab">
                <img src="../../assets/order/icon-bank.png" alt="icon_pay" />
              </div>
              {{ item.bankName }}
            </div>
            <div class="btn--add" @click="onBindBankCard">
              <a-icon type="plus" />
              添加绑定银行卡
            </div>
          </div>
          <div class="operation">
            <a-button class="btn--cancel" @click="onCancel">取消</a-button>
            <a-button class="btn--recharge" type="primary" @click="onRecharge">
              充值
            </a-button>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import BreadcrumbItem from "@/components/BreadcrumbItem";
import {
  checkWechatPayStatus,
  fetchAlipayQrCode,
  fetchAlipayResult,
  wechatOrderCreate
} from "@/services/PayService";
import { applyQuickPay, getUserBankList } from "@/services/FinancialService";
import QRCode from "qrcodejs2";

export default {
  name: "RechargeSecondStep",
  components: {
    BreadcrumbItem,
    Breadcrumb
  },
  data() {
    return {
      price: this.$route.params.price,
      tradeType: "T_H5",
      ip: "",
      payWay: "wechat",
      aliPayQrCode: null,
      hfSeqId: "", // 全局流水号
      reqSeqId: "", // 请求流水号
      huifuId: "",
      reqDate: "",
      timer: null,
      transStat: "",
      wechatPayResult: "",
      wechatUrl: "",
      orderId: "",
      bankList: [], // 用户绑定的银行卡列表
      choseBankInfo: {}, // 选中的支付银行卡信息
      currentIndex: 0 // 当前选中的银行卡的索引
    };
  },
  watch: {
    payWay(newVal) {
      clearInterval(this.timer);
      this.timer = null;
      this.orderId = "";
      if (newVal === "alipay") {
        this.$nextTick(() => {
          this.loadAlipayQrCode();
        });
      } else if (newVal === "wechat") {
        this.createWechatOrder();
      }
    }
  },
  created() {
    this.price = Number(this.price).toFixed(2);
    this.createWechatOrder();
  },
  mounted() {
    this.getUserBankList();
  },
  methods: {
    // 取消
    onCancel() {
      this.$router.go(-1);
    },
    // 银行卡充值
    async onRecharge() {
      if (!this.bankList.length) {
        this.$message.warning("请先绑定银行卡");
        return;
      }

      // 计算手续费 2借记卡，3借贷卡
      const type = this.choseBankInfo.cardType === "CC" ? 3 : 2;
      // 快捷绑卡申请
      const obj = {
        corporationId: localStorage.getItem("corporationId"),
        accountNumber: this.choseBankInfo.accountNumber,
        transAmt: this.price, // 交易金额
        realTransAmt: this.price, // 实付金额
        tradeType: type, // 交易渠道类型
        // copy api文档上的值
        ipAddr: "28.221.17.211",
        deviceIp: "28.221.17.211",
        deviceType: "1",
        bizTp: "100003",
        goodsShortName: "预付类账号充值",
        gwChnnlTp: "01" // 01：电脑浏览器，02：手机浏览器，03：手机应用程序，99：其他
      };
      const { data } = await applyQuickPay(obj);
      if (data.code === "SUCCESS") {
        // 拿到 orderId 跳到在线支付页
        this.$router.push({
          name: "bankPaid",
          query: {
            orderId: data.data.orderId,
            realTransAmt: this.price, // 实付金额
            transAmt: this.price, // 充值金额
            mobile: this.choseBankInfo.mobile
          }
        });
      } else {
        // 支付失败
        this.$message.error(data.errorMsg);
      }
    },
    // 绑定银行卡
    onBindBankCard() {
      this.$router.push({
        name: "BindBankCard",
        query: { price: this.$route.params.price }
      });
    },
    // 生成支付宝二维码
    loadAlipayQrCode() {
      this.$refs.alipayQrCode.innerHTML = "";
      const params = {
        transAmt: this.price,
        goodsDesc: "余额充值",
        corporationId: localStorage.getItem("corporationId"),
        // corporationId: "53cc37cd67da423bb7c0ddae7f5a1f5c",
        tradeType: "0",
        realTransAmt: this.price
      };
      fetchAlipayQrCode(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          let qrCodeUrl = resp.data.data.qrCode;
          this.reqSeqId = resp.data.data.reqSeqId;
          this.hfSeqId = resp.data.data.hfSeqId;
          this.huifuId = resp.data.data.huifuId;
          this.reqDate = resp.data.data.reqDate;
          this.orderId = resp.data.data.orderId;
          this.timer = setInterval(this.loadAlipayResult, 5000);
          this.aliPayQrCode = new QRCode(this.$refs.alipayQrCode, {
            text: qrCodeUrl, // 需要转换为二维码的内容
            width: 120,
            height: 120,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H
          });
        }
      });
    },
    // 查询Alipay支付结果
    loadAlipayResult() {
      const params = {
        orgReqDate: this.reqDate,
        huifuId: this.huifuId,
        orgHfSeqId: this.hfSeqId,
        orgReqSeqId: this.reqSeqId
      };
      fetchAlipayResult(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.transStat = resp.data.data.transStat;
          if (this.transStat === "S") {
            this.$router.push({
              name: "RechargeResult",
              params: { orderId: this.orderId }
            });
          } else if (this.transStat === "F") {
            this.$router.push({
              name: "RechargeResult",
              params: { orderId: this.orderId }
            });
          }
        }
      });
    },
    // 微信订单生成
    createWechatOrder() {
      const params = {
        payeeCorporationId: localStorage.getItem("corporationId"),
        // payeeCorporationId: "53cc37cd67da423bb7c0ddae7f5a1f5c",
        transAmt: this.price,
        realTransAmt: this.price
      };
      wechatOrderCreate(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          let data = resp.data.data;
          this.orderId = data.orderId;
          this.wechatUrl = "data:image/png;base64," + data.buffer;
          this.timer = setInterval(this.loadWechatResult, 5000);
        }
      });
    },
    // 查询wechatPay结果
    loadWechatResult() {
      checkWechatPayStatus(this.orderId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data.orderStatus === "3") {
            this.$router.push({
              name: "RechargeResult",
              params: { orderId: this.orderId }
            });
          } else if (data.orderStatus === "9") {
            this.$router.push({
              name: "RechargeResult",
              params: { orderId: this.orderId }
            });
          }
        }
      });
    },
    // 获取用户绑定的银行卡列表
    async getUserBankList() {
      const { data } = await getUserBankList({ cardType: "" });
      this.bankList = data.data || [];
      //  默认第一个银行卡支付
      this.choseBankInfo = this.bankList.length ? this.bankList[0] : {};
    },
    // 用户选中的支付银行卡
    chooseBank(item, index) {
      this.choseBankInfo = item;
      this.currentIndex = index;
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  }
};
</script>

<style scoped>
.main__container {
  min-height: 88vh;
  margin: 24px;
  background-color: white;
  box-sizing: border-box;
  padding: 20px 30px;
}

.select_way {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pay__num {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.pay__num span {
  color: rgba(255, 89, 106, 1);
  font-size: 30px;
  font-weight: 800;
}

.pay__num--unit {
  font-size: 16px !important;
  font-weight: bold;
}

.recharge__num {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.recharge__no {
  color: rgba(102, 102, 102, 0.8);
  display: flex;
  justify-content: center;
  margin: 10px 0 50px 0;
}

/deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  width: 110px;
  margin-right: 28px;
}

.pay__tab {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pay__tab img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.operation {
  margin: 45px 0 30px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn--cancel {
  width: 90px;
  margin-right: 30px;
}

.btn--recharge {
  width: 90px;
}

.bank__card__container {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-top: 20px;
}

.btn--add {
  width: 190px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: rgba(244, 245, 247, 1);
  border: 1px solid rgba(227, 227, 227, 1);
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 20px;
}

.btn--add /deep/ .anticon svg {
  margin-right: 5px;
}

.qr__code__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.qr__code__name {
  margin-top: 20px;
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
}

.wechat__img {
  width: 120px;
  height: 120px;
}
.actived {
  background-color: rgba(235, 240, 255, 1);
  border: 1px solid rgba(67, 104, 246, 1);
}
</style>
